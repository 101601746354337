import { Metric, ReportHandler } from "web-vitals";

export const reportHandler = (metric: Metric) => {
  if (process.env.NODE_ENV === "production") {
    return;
  }
  let name = metric.name as string;
  if (name === "CLS") {
    name = "Cumulative Layout Shift";
  }
  if (name === "FID") {
    name = "First Input Delay";
  }
  if (name === "LCP") {
    name = "Largest Contentful Paint";
  }
  if (metric.value > 1) {
    console.log(`${metric.name} performance is poor (value: ${metric.value})`);
  }
};

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getLCP(onPerfEntry);
    });
  }
};

export default reportWebVitals;
