import { ReactNode } from "react";

import "./Card.scss";

interface CardProps {
  description: string;
  value: string;

  icon?: ReactNode;
  highlightElement?: ReactNode;

  className?: string;
}

export default function Card({ description, value, icon, highlightElement, className = "" }: CardProps) {
  return (
    <div className={`card ${className}`}>
      {!!icon && <div className="card-icon-container">{icon}</div>}
      <div className="card-text-container">
        <p className="description">{description}</p>
        <h3 className="result">{value}</h3>
      </div>
      {highlightElement}
    </div>
  );
}
