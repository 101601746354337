import { useContext, useMemo } from "react";

import { TokenContext } from "contexts/token";

import useLatestOrder from "utils/hooks/useLatestOrder";

const isValidDate = (date: any) => date instanceof Date && !isNaN(date.valueOf());

export default function LatestOrderCard() {
  const { token } = useContext(TokenContext);

  const { latestOrder } = useLatestOrder(token);

  const timestamp = useMemo(() => {
    if (!latestOrder) {
      return "";
    }
    let date = new Date(latestOrder.time);
    if (!isValidDate(date)) {
      return "";
    }

    let hours = date.getHours().toString();
    if (hours.length === 1) {
      hours = `0${hours}`;
    }

    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
  }, [latestOrder]);

  const productList = useMemo(() => {
    if (!latestOrder) {
      return [];
    }

    // Attempting to sort bag fee last, if it exists
    return [...latestOrder.products].sort((a, b) => {
      let aIsBagFee = a.toLowerCase().includes("pose");
      let bIsBagFee = b.toLowerCase().includes("pose");
      if (!aIsBagFee && !bIsBagFee) {
        return 0;
      }
      return aIsBagFee ? 1 : -1;
    });
  }, [latestOrder]);

  if (!latestOrder) {
    return <div className="card latest-order-card"></div>;
  }

  return (
    <div className="card dashboard-element latest-order-card">
      <div className="card-text-container">
        <h3 className="latest-order-title">
          {latestOrder.companyName}, {timestamp}
        </h3>
        {productList.slice(0, 4).map((productName, index) => (
          <p key={`${productName}-${index}`} className="latest-order-product">
            {productName}
          </p>
        ))}
        {productList.length > 4 && (
          <p key="productname-ellipsis" className="latest-order-product">
            ...
          </p>
        )}
      </div>
    </div>
  );
}
