import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/ui/Card";

import { OrderLogContext } from "contexts/orders";

import orderCountIcon from "assets/img/icons/shopping-bag.svg";
import orderSumIcon from "assets/img/icons/turnover.svg";
import calendarIcon from "assets/img/icons/calendar.svg";

import "./CurrentWeek.scss";

export default function CurrentWeekSection() {
  const { t } = useTranslation();

  const { currentWeek, lastWeek } = useContext(OrderLogContext);

  const orderTotalSumAcc = useMemo(() => currentWeek.reduce((totalSum: number, orderLog: OrderLog) => totalSum + orderLog.orderTotalSum, 0), [currentWeek]);
  const orderCountAcc = useMemo(() => currentWeek.reduce((totalCount: number, orderLog: OrderLog) => totalCount + orderLog.orderCount, 0), [currentWeek]);

  const orderTotalSumValueString = useMemo(
    () => (orderTotalSumAcc > 0 ? `${orderTotalSumAcc.toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${t("common:Currency")}` : "-"),
    [orderTotalSumAcc, t]
  );
  const orderCountValueString = useMemo(() => (orderCountAcc > 0 ? `${orderCountAcc.toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${t("common:OrderCount")}` : "-"), [orderCountAcc, t]);

  const yesterday: OrderLog | undefined = useMemo(() => currentWeek[currentWeek.length - 1] || lastWeek[6], [currentWeek, lastWeek]);

  const yesterdayOrderTotalSumValueString = useMemo(
    () => (yesterday?.orderTotalSum > 0 ? `${yesterday.orderTotalSum.toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${t("common:Currency")}` : "-"),
    [yesterday, t]
  );

  const yesterdayOrderCountValueString = useMemo(
    () => (yesterday?.orderCount > 0 ? `${yesterday.orderCount.toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${t("common:OrderCount")}` : "-"),
    [yesterday, t]
  );

  return (
    <div className="dashboard-section">
      <Card
        className="dashboard-element"
        description={t("orders:WeekSummary.Sum")}
        value={orderTotalSumValueString}
        icon={<img src={orderSumIcon} alt="" />}
        highlightElement={
          <Card className="current-week-highlight-card" description={t("orders:YesterdaySummary.Sum")} value={yesterdayOrderTotalSumValueString} icon={<img src={calendarIcon} alt="" />} />
        }
      />
      <Card
        className="dashboard-element"
        description={t("orders:WeekSummary.Count")}
        value={orderCountValueString}
        icon={<img src={orderCountIcon} alt="" />}
        highlightElement={
          <Card className="current-week-highlight-card" description={t("orders:YesterdaySummary.Count")} value={yesterdayOrderCountValueString} icon={<img src={calendarIcon} alt="" />} />
        }
      />
    </div>
  );
}
