import { get } from "@crunchit/http-client";

const api = process.env.REACT_APP_DASHBOARD_URI || `dashboard.crunchy.ninja/api`;

const DashboardService = {
  getOrderLog: async (token: string, params: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = params;
    return get<OrderLog[]>(`${api}/orderlog/${startDate},${endDate},false`, token);
  },
  getAccumulatedOrderLog: async (token: string, params: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = params;
    return get<OrderLog>(`${api}/orderlog/${startDate},${endDate},true`, token);
  },
  getLatestOrder: async (token: string, date: string) => {
    return get<OrderSummary>(`${api}/ordersummary/${date}`, token);
  },
};

export default DashboardService;
