import "./Toggle.scss";

const defaultColor = "#B1B1B1";

interface ToggleProps {
  color?: string;
  toggleId: string;
  isChecked: boolean;
  handleToggleChange: (updatedIsChecked: boolean) => void;
}

export default function Toggle({ toggleId, color = defaultColor, isChecked, handleToggleChange }: ToggleProps) {
  function handleClick(event: any) {
    event.preventDefault();
    handleToggleChange(!isChecked);
  }

  return (
    <div className="toggle" onClick={handleClick}>
      <input type="checkbox" id={toggleId} checked={isChecked} readOnly />
      <label htmlFor={toggleId} style={{ background: isChecked ? color : defaultColor }}>
        Toggle
      </label>
    </div>
  );
}
