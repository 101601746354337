import { useEffect, useState } from "react";

const hoursBetweenRefreshing = 6;

// Refreshing the page in intervals
export default function usePageRefresh() {
  let [lastRefresh] = useState(new Date().getTime());
  let [checkForRefresh, setCheckForRefresh] = useState(false);

  let [timeOfLastRefreshCheck, setTimeOfLastRefreshCheck] = useState(new Date().getTime()); // Last time we checked how long has passed
  let [timeOfLastWorkerMessage, setTimeOfLastWorkerMessage] = useState(new Date().getTime()); // Last time the worker was triggered

  useEffect(() => {
    if (checkForRefresh) {
      let now = new Date().getTime();
      setTimeOfLastRefreshCheck(now);

      let millisecondsSinceLastRefresh = now - lastRefresh;
      let hoursSinceLastRefresh = millisecondsSinceLastRefresh / 1000 / 60 / 60;

      if (hoursSinceLastRefresh >= hoursBetweenRefreshing) {
        window.location.reload();
      } else {
        setCheckForRefresh(false);
      }
    }
  }, [checkForRefresh, lastRefresh]);

  useEffect(() => {
    let worker = new Worker("intervalWorker.js");
    worker.onmessage = function () {
      setTimeOfLastWorkerMessage(new Date().getTime());
      setCheckForRefresh(true);
    };
    return () => worker.terminate();
  }, []);

  return { lastRefresh, timeOfLastRefreshCheck, timeOfLastTimeout: timeOfLastWorkerMessage };
}
