import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/ui/Card";
import LatestOrderCard from "./LatestOrder";

import { OrderLogContext } from "contexts/orders";

import "./CurrentYear.scss";

export default function CurrentYearSection() {
  const { t } = useTranslation();

  const { yearToDate } = useContext(OrderLogContext);

  const orderTotalSumValueString = useMemo(
    () => (yearToDate && yearToDate.orderTotalSum > 0 ? `${yearToDate.orderTotalSum.toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${t("common:Currency")}` : "-"),
    [yearToDate, t]
  );

  const orderCountValueString = useMemo(
    () => (yearToDate && yearToDate.orderCount > 0 ? `${yearToDate.orderCount.toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${t("common:OrderCount")}` : "-"),
    [yearToDate, t]
  );

  return (
    <div className="dashboard-section current-year-section">
      <Card className="dashboard-element" description={t("orders:YearSummary.Sum")} value={orderTotalSumValueString} />
      <Card className="dashboard-element" description={t("orders:YearSummary.Count")} value={orderCountValueString} />
      <LatestOrderCard />
    </div>
  );
}
