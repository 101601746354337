import { useTranslation } from "react-i18next";

import Button from "components/ui/Button";

import "./ResetToken.scss";

export default function ResetTokenButton({ resetToken }: { resetToken: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="reset-token-button-container">
      <Button size="small" onClick={resetToken}>
        {t("token:Reset")}
      </Button>
    </div>
  );
}
