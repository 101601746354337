import WeekdaySumGraph from "./WeekdaySum";
import WeekdayCountGraph from "./WeekdayCount";

import "./Weekdays.scss";

export default function WeekdaysGraphSection() {
  return (
    <div className="dashboard-section weekday-graphs-section">
      <WeekdaySumGraph />
      <WeekdayCountGraph />
    </div>
  );
}
