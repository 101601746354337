import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "./ui/Button";

import { TokenContext } from "contexts/token";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "utils/helpers/storage";

import logo from "assets/img/logo.png";

import "./Token.scss";

export default function Token() {
  const { t } = useTranslation();

  const { setToken } = useContext(TokenContext);

  let [editedToken, setEditedToken] = useState(getFromLocalStorage("customerToken") || "");

  useEffect(() => {
    // Removing locally stored token on mount
    removeFromLocalStorage("customerToken");
  }, []);

  const handleInputChange = useCallback((value: string) => {
    setEditedToken(value || "");
  }, []);

  const handleKeyDown = (key: string) => {
    if (editedToken && key === "Enter") {
      handleSave();
    }
  };

  // When a token is set, we redirect
  const handleSave = () => {
    if (!editedToken) {
      return;
    }
    saveToLocalStorage("customerToken", editedToken);
    setToken(editedToken);
  };

  return (
    <div className="page token-page">
      <div className="token-title-container">
        <img className="logo" alt="logo" src={logo} />
        <h1 className="token-title">{t("common:AppTitle")}</h1>
      </div>

      <div className="modal-container token-modal">
        <div className="modal-header">
          <h3 className="modal-header-title">{t("token:Title")}</h3>
        </div>
        <div className="modal-body">
          <h3 className="input-title">{t("token:InputTitle")}</h3>
          <div className="form-inputs">
            <input
              className="token-input"
              placeholder={t("token:Placeholder")}
              value={editedToken}
              name="token"
              type="text"
              onKeyDownCapture={(event) => handleKeyDown(event.key)}
              onChange={(event) => handleInputChange(event.target.value)}
            />
          </div>
          <div className="actions-section">
            <Button disabled={!editedToken} onClick={handleSave}>
              {t("common:Actions.Continue")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
