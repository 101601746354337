import { useEffect, useState } from "react";

import DashboardService from "services/DashboardService";

const refreshIntervalInMinutes = 1;

export default function useLatestOrder(token: string | null) {
  let [latestOrder, setLatestOrder] = useState<OrderSummary | null>(null);

  let [reload, setReload] = useState(true);

  useEffect(() => {
    let mounted = true;
    async function reloadData(token: string) {
      let orderSummaryResponse = await DashboardService.getLatestOrder(token, new Date().toISOString());
      if (!mounted || !orderSummaryResponse.isSuccess()) {
        return;
      }
      setLatestOrder(orderSummaryResponse.data);
      setReload(false);
    }
    if (reload && token) {
      reloadData(token);
    }
    return () => {
      mounted = false;
    };
  }, [reload, token]);

  useEffect(() => {
    let interval = setInterval(() => setReload(true), 1000 * 60 * refreshIntervalInMinutes);
    return () => clearInterval(interval);
  }, []);

  return {
    latestOrder,
  };
}
