import { useEffect, useState } from "react";

import DashboardService from "services/DashboardService";
import { getLatestMondayDate } from "utils/helpers/dates";

export default function useOrderLogs(token: string | null) {
  let [currentWeek, setCurrentWeek] = useState<OrderLog[]>([]);
  let [lastWeek, setLastWeek] = useState<OrderLog[]>([]);
  let [currentWeekLastYear, setCurrentWeekLastYear] = useState<OrderLog[]>([]);

  let [yearToDate, setYearToDate] = useState<OrderLog | null>(null);

  // Current and last week
  useEffect(() => {
    let mounted = true;
    async function loadCurrentAndLastWeek(token: string) {
      let startDate = getLatestMondayDate(new Date());
      startDate.setDate(startDate.getDate() - 7);

      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      let orderLogResponse = await DashboardService.getOrderLog(token, { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
      if (!mounted || !orderLogResponse.isSuccess()) {
        return;
      }
      setLastWeek(orderLogResponse.data.slice(0, 7));
      setCurrentWeek(orderLogResponse.data.slice(7));
    }
    if (token) {
      loadCurrentAndLastWeek(token);
    }
    return () => {
      mounted = false;
    };
  }, [token]);

  // Same week last year
  useEffect(() => {
    let mounted = true;
    async function loadCurrentWeekLastYear(token: string) {
      let startDate = getLatestMondayDate(new Date());
      startDate.setDate(startDate.getDate() - 52 * 7);

      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 51 * 7 - 1);
      let orderLogResponse = await DashboardService.getOrderLog(token, { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
      if (!mounted || !orderLogResponse.isSuccess()) {
        return;
      }
      setCurrentWeekLastYear(orderLogResponse.data.slice(0, 7));
    }
    if (token) {
      loadCurrentWeekLastYear(token);
    }
    return () => {
      mounted = false;
    };
  }, [token]);

  // Accumulated for this year to date
  useEffect(() => {
    let mounted = true;
    async function loadYearToDate(token: string) {
      let startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      startDate.setMonth(11);
      startDate.setDate(31);

      let endDate = new Date();
      let orderLogResponse = await DashboardService.getAccumulatedOrderLog(token, { startDate: startDate.toISOString(), endDate: endDate.toISOString() });
      if (!mounted || !orderLogResponse.isSuccess()) {
        return;
      }
      setYearToDate(orderLogResponse.data);
    }
    if (token) {
      loadYearToDate(token);
    }
    return () => {
      mounted = false;
    };
  }, [token]);

  return {
    currentWeek,
    lastWeek,
    currentWeekLastYear,
    yearToDate,
  };
}
