import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { OrderLogContext } from "contexts/orders";

import { colors } from "utils/constants/colors";

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const currentWeekData: LineGraphDataEntry = { id: "graphs:CurrentWeek", color: colors.currentWeekGraphColor, data: [] };
const lastWeekData: LineGraphDataEntry = { id: "graphs:LastWeek", color: colors.lastWeekGraphColor, data: [] };
const currentWeekLastYearData: LineGraphDataEntry = { id: "graphs:CurrentWeekLastYear", color: colors.currentWeekLastYearGraphColor, data: [] };

const isValidDate = (date: any) => date instanceof Date && !isNaN(date.valueOf());

export default function useWeekGraphData() {
  const { t } = useTranslation();

  const { currentWeek, lastWeek, currentWeekLastYear } = useContext(OrderLogContext);

  const getWeekdayName = useCallback(
    (orderLogDate: string) => {
      let date = new Date(orderLogDate);
      let weekday = isValidDate(date) ? date.getDay() : 0;
      return t(`common:Weekdays.${weekdays[weekday]}`).slice(0, 3);
    },
    [t]
  );

  const mapOrderCount = useCallback(
    (value: OrderLog): LineGraphDataPoint => {
      let weekdayName = getWeekdayName(value.date);
      return { x: weekdayName, y: value.orderCount };
    },
    [getWeekdayName]
  );

  const mapOrderTotalSum = useCallback(
    (value: OrderLog): LineGraphDataPoint => {
      let weekdayName = getWeekdayName(value.date);
      return { x: weekdayName, y: value.orderTotalSum };
    },
    [getWeekdayName]
  );

  const getOrderTotalSumData = useCallback(
    ({ isShowingLastWeek, isShowingLastYear }: { isShowingLastWeek: boolean; isShowingLastYear: boolean }): LineGraphDataEntry[] => {
      let current = { ...currentWeekData };
      current.id = t(current.id);
      current.data = currentWeek.map(mapOrderTotalSum);

      let last = { ...lastWeekData };
      last.id = t(last.id);
      last.data = isShowingLastWeek ? lastWeek.map(mapOrderTotalSum) : [];

      let lastYear = { ...currentWeekLastYearData };
      lastYear.id = t(lastYear.id);
      lastYear.data = isShowingLastYear ? currentWeekLastYear.map(mapOrderTotalSum) : [];

      return [current, last, lastYear];
    },
    [currentWeek, lastWeek, currentWeekLastYear, mapOrderTotalSum, t]
  );

  const getOrderCountData = useCallback(
    ({ isShowingLastWeek, isShowingLastYear }: { isShowingLastWeek: boolean; isShowingLastYear: boolean }): LineGraphDataEntry[] => {
      let current = { ...currentWeekData };
      current.id = t(current.id);
      current.data = currentWeek.map(mapOrderCount);

      let last = { ...lastWeekData };
      last.id = t(last.id);
      last.data = isShowingLastWeek ? lastWeek.map(mapOrderCount) : [];

      let lastYear = { ...currentWeekLastYearData };
      lastYear.id = t(lastYear.id);
      lastYear.data = isShowingLastYear ? currentWeekLastYear.map(mapOrderCount) : [];

      return [current, last, lastYear];
    },
    [currentWeek, lastWeek, currentWeekLastYear, mapOrderCount, t]
  );

  return {
    getOrderTotalSumData,
    getOrderCountData,
  };
}
