import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TokenContext } from "contexts/token";

import ResetTokenButton from "components/ResetToken";
import { CurrentWeekSection, CurrentYearSection } from "./cards";
import { WeekdaysGraphSection } from "./graphs";

import useOrderLogs from "utils/hooks/useOrderLogs";
import usePageRefresh from "utils/hooks/usePageRefresh";

import { OrderLogContext } from "contexts/orders";

import "./dashboard.scss";

export default function Dashboard() {
  const { token, setToken } = useContext(TokenContext);

  const { t } = useTranslation();

  // Refreshing the page intermittently
  usePageRefresh();

  const { currentWeek, lastWeek, currentWeekLastYear, yearToDate } = useOrderLogs(token);
  const orderLogContextValue = useMemo(() => ({ currentWeek, lastWeek, currentWeekLastYear, yearToDate }), [currentWeek, lastWeek, currentWeekLastYear, yearToDate]);

  return (
    <div className="dashboard">
      <div className="page-header">
        <h2 className="header-title">{t("common:AppTitle")}</h2>
      </div>
      <OrderLogContext.Provider value={orderLogContextValue}>
        <div className="page-content dashboard-content">
          <ResetTokenButton resetToken={() => setToken(null)} />
          <CurrentWeekSection />
          <WeekdaysGraphSection />
          <CurrentYearSection />
        </div>
      </OrderLogContext.Provider>
    </div>
  );
}
