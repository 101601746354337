import { createContext } from "react";

export type OrderLogContextType = {
  currentWeek: OrderLog[];
  lastWeek: OrderLog[];
  currentWeekLastYear: OrderLog[];
  yearToDate: OrderLog | null;
};

const defaultContext: OrderLogContextType = {
  currentWeek: [],
  lastWeek: [],
  currentWeekLastYear: [],
  yearToDate: null,
};

export const OrderLogContext = createContext<OrderLogContextType>(defaultContext);
