import { Dispatch, SetStateAction, createContext } from "react";

export type TokenContextType = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
};

const defaultContext: TokenContextType = {
  token: null,
  setToken: (): void => {},
};

export const TokenContext = createContext<TokenContextType>(defaultContext);
