import { ReactNode } from "react";

import "./Button.scss";

type ButtonTheme = "default" | "rounded" | "text";
type ButtonSize = "large" | "medium" | "small";

interface ButtonProps {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  theme?: ButtonTheme;
  size?: ButtonSize;
  onClick?: () => void;
}

export default function Button(props: ButtonProps) {
  const { children, disabled, className = "", theme = "default", size = "medium" } = props;
  const { onClick } = props;

  return (
    <button className={`button ${theme}-button ${size}-button ${className}`} disabled={disabled} data-testid="button" onClick={onClick}>
      {children}
    </button>
  );
}
