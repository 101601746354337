import { useMemo, useState } from "react";
import { TokenContext } from "contexts/token";

import Token from "components/Token";
import Dashboard from "components/dashboard";

import { getFromLocalStorage } from "utils/helpers/storage";

import "./App.scss";

function App() {
  let [token, setToken] = useState(getFromLocalStorage("customerToken" || null));

  const contextValue = useMemo(() => ({ token, setToken }), [token]);

  return (
    <div className="app-page">
      <TokenContext.Provider value={contextValue}>{token ? <Dashboard /> : <Token />}</TokenContext.Provider>
    </div>
  );
}

export default App;
