import "react-app-polyfill/stable";

import ReactDOM from "react-dom/client";

import App from "./App";
import ErrorBoundary from "components/ErrorBoundary";

import reportWebVitals, { reportHandler } from "./reportWebVitals";

import "i18n";

import "normalize.css/normalize.css";
import "assets/styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

reportWebVitals(reportHandler);
