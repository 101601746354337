import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import LineGraph from "components/ui/LineGraph";
import Toggle from "components/ui/Toggle";
import GraphLabel from "components/ui/GraphLabel";

import useWeekComparison from "utils/hooks/useWeekComparison";
import useWeekGraphData from "utils/hooks/useWeekGraphData";

import { colors } from "utils/constants/colors";

import lastWeekIcon from "assets/img/icons/graph-last-week.svg";
import lastYearIcon from "assets/img/icons/graph-last-year.svg";

export default function WeekdayCountGraph() {
  const { t } = useTranslation();

  const { getOrderCountData } = useWeekGraphData();
  const { countComparedToLastWeek, countComparedToLastYear } = useWeekComparison();

  let [isShowingLastWeek, setIsShowingLastWeek] = useState(true);
  let [isShowingLastYear, setIsShowingLastYear] = useState(true);

  const orderCountData = useMemo(() => getOrderCountData({ isShowingLastWeek, isShowingLastYear }), [isShowingLastWeek, isShowingLastYear, getOrderCountData]);

  return (
    <div className="dashboard-element">
      <LineGraph title={t("orders:WeekdayGraph.Count")} data={orderCountData} yFormat=" >-.0f" />
      <div className="graph-labels">
        <div className="graph-label-container">
          <GraphLabel value={countComparedToLastWeek.value} valuePercentage={countComparedToLastWeek.percentage} valueType="count" labelDescription={t("graphs:LastWeek")} iconSrc={lastWeekIcon} />
          <Toggle toggleId="lastWeek" color={colors.lastWeekGraphColor} isChecked={isShowingLastWeek} handleToggleChange={setIsShowingLastWeek} />
        </div>
        <div className="graph-label-container">
          <GraphLabel
            value={countComparedToLastYear.value}
            valuePercentage={countComparedToLastYear.percentage}
            valueType="count"
            labelDescription={t("graphs:CurrentWeekLastYear")}
            iconSrc={lastYearIcon}
          />
          <Toggle toggleId="currentWeekLastYearData" color={colors.currentWeekLastYearGraphColor} isChecked={isShowingLastYear} handleToggleChange={setIsShowingLastYear} />
        </div>
      </div>
    </div>
  );
}
