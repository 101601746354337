export const siteStorageName = "crunchorder-dashboard";

const loadStorage = () => {
  try {
    let serializedState = localStorage.getItem(siteStorageName);

    if (serializedState === null) {
      return undefined;
    }

    return serializedState ? JSON.parse(serializedState) : serializedState;
  } catch (e) {
    return undefined;
  }
};

const saveToStorage = (storageobject: any) => {
  try {
    let serializedState = JSON.stringify(storageobject, function replacer(key, value) {
      return value;
    });
    localStorage.setItem(siteStorageName, serializedState);
  } catch (e) {}
};

const getFromLocalStorage = (key: string) => {
  let persistedState = loadStorage();
  return persistedState !== undefined ? persistedState[key] : undefined;
};

const saveToLocalStorage = (key: string, value: string) => {
  let persistedState = loadStorage();
  if (persistedState === undefined) {
    persistedState = {};
  }
  persistedState[key] = value;
  saveToStorage(persistedState);
};

const removeFromLocalStorage = (key: string) => {
  let persistedState = loadStorage();
  if (persistedState !== undefined) {
    delete persistedState[key];
    saveToStorage(persistedState);
    return true;
  } else {
    return false;
  }
};

const clearAllLocalStorage = () => {
  let persistedState = {};
  saveToStorage(persistedState);
};

export { getFromLocalStorage, saveToLocalStorage, removeFromLocalStorage, clearAllLocalStorage };
