import common from "./common.json";
import token from "./token.json";
import orders from "./orders.json";
import graphs from "./graphs.json";

export const da = {
  common,
  token,
  orders,
  graphs,
};
