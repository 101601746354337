import { ResponsiveLine } from "@nivo/line";

import "./LineGraph.scss";

interface LineGraphProps {
  className?: string;
  title?: string;
  data: LineGraphDataEntry[];

  axisLeft?: Object;
  yFormat?: string;
}

export default function LineGraph({ className = "", title, data, axisLeft, yFormat }: LineGraphProps) {
  return (
    <div className={`line-graph-container ${className}`}>
      <h3 className="graph-title">{title}</h3>
      <div className="line-graph">
        <ResponsiveLine
          data={data}
          axisLeft={axisLeft}
          yFormat={yFormat}
          animate
          curve="catmullRom"
          enableSlices="x"
          colors={{
            datum: "color",
          }}
          margin={{
            bottom: 30,
            left: 50,
            right: 20,
            top: 30,
          }}
          yScale={{
            type: "linear",
            min: "auto",
          }}
        />
      </div>
    </div>
  );
}
